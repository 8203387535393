import React, { useState } from 'react';
import FileUpload from './components/Dropzone';
import { iterativeImageCompression, changeFileExtensionIfNeeded } from './helpers/image-helpers';
import JSZip from 'jszip';


const App = () => {
  const [files, setFiles] = useState([]);
  const [compressedFiles, setCompressedFiles] = useState([]);

  const handleFilesAdded = async (addedFiles) => {
    const updatedFiles = addedFiles.map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setFiles([...files, ...updatedFiles]);
    const tmpCompressedFiles = [];

    for (const file of addedFiles) {
      if (file.type.startsWith('image/')) {

        // Change the file extension if needed (e.g., from .png to .jpg)
        const modifiedFile = changeFileExtensionIfNeeded(file);

        // Compress the image using iterativeImageCompression
        const compressedFile = await iterativeImageCompression(modifiedFile);
        console.log(compressedFile);

        // Add the compressed file to the array
        tmpCompressedFiles.push(compressedFile);
      }
    }

    // Update the state with the compressed files
    setCompressedFiles([...compressedFiles, ...tmpCompressedFiles]);
    console.log(compressedFiles);
  };

  const downloadZip = () => {
    const zip = new JSZip();

    console.log(compressedFiles);

    // Add each compressed file to the zip folder
    compressedFiles.forEach((file, index) => {
      zip.file(`compressed_${index}_${file.name}`, file);
    });

    // Generate the zip folder
    zip.generateAsync({ type: 'blob' }).then((content) => {
      // Create a download link and trigger the download
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(content);
      downloadLink.download = 'compressed_files.zip';
      downloadLink.click();
    });
    setCompressedFiles([]);
    setFiles([]);
  };

  return (
    <div className="app">
      <h1>Drag and Drop Image Compression</h1>
      <FileUpload onFilesAdded={handleFilesAdded} />
      {files.length > 0 && (
        <div>
          <h2>Uploaded Files:</h2>
          <ul>
            {files.map((file, index) => (
              <li key={index}>
                {file.name} ({formatFileSize(file.size)})
              </li>
            ))}
          </ul>
          <h2>Compressed Files:</h2>
          <ul>
            {compressedFiles.map((file, index) => (
              <li key={index}>
                {file.name} ({formatFileSize(file.size)})
              </li>
            ))}
          </ul>
          {compressedFiles.length > 0 && (
            <button onClick={downloadZip}>Download Compressed Files as Zip</button>
          )}
        </div>
      )}
      <div className="version">Version 0.1</div>
    </div>
  );
};

// Helper function to format file size
const formatFileSize = (size) => {
  const KB = 1024;
  const MB = KB * 1024;
  const GB = MB * 1024;

  if (size >= GB) {
    return `${(size / GB).toFixed(2)} GB`;
  } else if (size >= MB) {
    return `${(size / MB).toFixed(2)} MB`;
  } else if (size >= KB) {
    return `${(size / KB).toFixed(2)} KB`;
  } else {
    return `${size} bytes`;
  }
};

export default App;
